<template>
   <section class="content-container">
      <SectionTabs :tabs="tab_component"/>
      <router-view/>
   </section>
</template>
<script>
import SectionTabs from "@/components/Section/SectionTabs.vue";

export default {
   components: { 
      SectionTabs
   },
   data() {
      return {
         title: "Seguridad",
         tab_component: [
            {
               name: "Inicio",
               route: "seguridad-list",
            },
            {
               name: "Agregar",
               route: "seguridad-add",
            },
            {
               name: "Categorías",
               route: "seguridad-add-categories",
            },
         ]
      };
   },
   mounted() {
      if (this.$route.name == "seguridad-edit") {
         this.tab_component[1].name = "Editar";
      } else {
         this.tab_component[1].name = "Agregar";
      }
   },
   methods: {
      goTo(route) {
         var current_route = this.$route.name;
         if (route == current_route) {
            return;
         } else {
            this.$router.push({ name: route });
         }
      },
      activeTab(tab) {
         var current_route = this.$route.name;
         if (tab == current_route) {
            return "active";
         }
      },
   },
};
</script>